export default {
    methods: {
        getSanitaireShutters(props, callback=() => false) {
            return {
                'horse-sanitaire': {
                    name: 'horse-sanitaire',
                    title: this.$t('sanitaire.list'),
                    onOk: callback,
                    cmpPath: 'components/Sanitaire/Horse',
                    props
                },
                'sanitaire-result': {
                    name: 'sanitaire-result',
                    title: this.$t('sanitaire.edit'),
                    onOk: callback,
                    cmpPath: 'components/Sanitaire/Result',
                    props
                },            
            }
        },

        setupHorseSanitaire(horse_id) {
            const shutters = this.getSanitaireShutters({
                horse_id
            })

            this.shutterPanel().open(shutters['horse-sanitaire'])
        },

        setupSanitaireResult(results, callback) {
            const shutters = this.getSanitaireShutters({
                results
            }, callback)

            this.shutterPanel().open(shutters['sanitaire-result'])
        },
    }
}
